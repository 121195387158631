import '../Welcome.css'
import Component from '../ComponentTest';

export default function IndexPage() {
    return(
        <div>  
            <Component />
        </div>
    );

}