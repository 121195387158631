import Component from "../ComponentTest";
import Customizer from "../Custoizer";

export default function TestingPage() {
    return(
        <div>
       <><h1>Under developed</h1></>
        </div>
    );

}