import { Link } from "react-router-dom";
export default function Header(){
    return(

    <header>
            <Link to="/" className="logo">HOME</Link>
        <nav>
            <Link to="/testpage" >Login</Link>   
            <Link to="/testpage" >Register</Link>          
        </nav>
    </header>
    

    );
}