

export default function Component(){
  let w = window.innerWidth; 
  let h = window.innerHeight;
    return(
      <div className="test-1">
        <div className="image">
        <img src={require('./logoacubiq.png')} alt="acubiq" />

        </div>
          <landscape>Landsape mode</landscape>
          <phone>Portrait Mode</phone>
          <desktop>Desktop</desktop>
       <p>window Height = {h} and Width is {w}</p>
     </div>
    );
}